<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách nhà cung cấp'">
          <template v-slot:preview>
            <v-card>
              <v-card-title>
                <router-link to="/stores/add-store">
                  <div class="my-2">
                    <v-btn color="warning" dark>Tạo mới</v-btn>
                  </div>
                </router-link>
                <!-- <v-spacer></v-spacer> -->
              </v-card-title>
              <div class="col-md-12 mb-5">
                <div class="row my-5">
                  <div class="col-md-6">
                    <b-form inline>
                      <label for="inline-form-input-name" class="col-md-4"
                        >Id:</label
                      >
                      <b-input
                        id="inline-form-input-name"
                        placeholder
                        v-model="search"
                        append-icon="search"
                        single-line
                        hide-details
                        class="col-md-8"
                      ></b-input>
                    </b-form>
                  </div>
                  <div class="col-md-6">
                    <b-form inline>
                      <label for="inline-form-input-name" class="col-md-4"
                        >Tên:</label
                      >
                      <b-input
                        id="inline-form-input-name"
                        placeholder
                        v-model="search"
                        append-icon="search"
                        single-line
                        hide-details
                        class="col-md-8"
                      ></b-input>
                    </b-form>
                  </div>
                </div>
                <div class="row my-5">
                  <div class="col-md-6">
                    <b-form inline>
                      <label for="inline-form-input-name" class="col-md-4"
                        >Điện thoại:</label
                      >
                      <b-input
                        id="inline-form-input-name"
                        placeholder
                        v-model="search"
                        append-icon="search"
                        single-line
                        hide-details
                        class="col-md-8"
                      ></b-input>
                    </b-form>
                  </div>
                  <div class="col-md-6 ">
                    <b-form inline>
                      <label for="inline-form-input-name" class="col-md-4"
                        >Loại:</label
                      >
                      <b-form-select
                        v-model="selected"
                        :options="listType"
                        value-field="id"
                        class="col-md-8"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-select>
                    </b-form>
                  </div>
                </div>
                <div class="row my-5">
                  <div class="col-md-6">
                    <b-form inline>
                      <label for="inline-form-input-name" class="col-md-4"
                        >Trạng thái:</label
                      >
                      <b-input
                        id="inline-form-input-name"
                        placeholder
                        v-model="search"
                        append-icon="search"
                        single-line
                        hide-details
                        class="col-md-8"
                      ></b-input>
                    </b-form>
                  </div>
                </div>
              </div>

              <v-data-table
                class="table-bordered table-hover col-md-12"
                :headers="headers"
                :items="desserts"
                :search="search"
                :disable-pagination="true"
                :hide-default-footer="true"
              >
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex justify-content-center">
                    <v-icon
                      small
                      class="mr-2 text-primary"
                      @click="editItem(item)"
                      >mdi-pencil</v-icon
                    >
                    <v-icon
                      small
                      class="mr-2 text-danger"
                      @click="deleteItem(item)"
                      >mdi-delete</v-icon
                    >
                  </div>
                </template>
              </v-data-table>
              <div class="overflow-auto">
                <b-pagination-nav
                  :link-gen="linkGen"
                  :number-of-pages="10"
                  use-router
                ></b-pagination-nav>
              </div>
            </v-card>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "id"
        },
        { text: "Mã nhà cung cấp", value: "code" },
        { text: "Tên", value: "name" },
        { text: "Loại", value: "type" },
        { text: "Điện thoại", value: "phoneNumber" },
        { text: "Người tạo", value: "createBy" },
        { text: "Trạng thái", value: "status" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      desserts: [
        {
          id: 1,
          name: "Samsung VN",
          code: "sam-vn",
          type: "Doanh nghiệp",
          phoneNumber: "0123456789",
          createBy: "HienNT",
          status: "Hoạt động"
        },
        {
          id: 2,
          name: "Apple VN",
          code: "apple-vn",
          type: "Doanh nghiệp",
          phoneNumber: "0123456789",
          createBy: "HienNT",
          status: "Hoạt động"
        },
        {
          id: 3,
          name: "Thái Hiền",
          code: "hiennt",
          type: "Cá nhân",
          phoneNumber: "0123456789",
          createBy: "HienNT",
          status: "Hoạt động"
        },
        {
          id: 4,
          name: "Chí Trung",
          code: "trungndc",
          type: "Cá nhân",
          phoneNumber: "0123456789",
          createBy: "HienNT",
          status: "Hoạt động"
        }
      ],
      selected: "",
      listType: [
        { id: 1, name: "Cá nhân" },
        { id: 2, name: "Doanh nghiệp" }
      ]
    };
  },
  components: {
    KTCodePreview
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);
  }
};
</script>
